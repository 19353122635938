<template>
  <div class="linkman">
    <PageTop
      :tabsShow="true"
      @addMeans="addLands"
      @searchLinkman="searchLinkman"
      :linkmanShow="true"
    >
      <div class="adTitle" slot="adTitle">供应商管理</div>
      <p slot="addName">新增供应商</p>
    </PageTop>
    <div class="mains">
      <div class="lists" v-if="perList.length">
        <div
          class="items animated fadeInUp"
          :class="item.category == 1 ? 'active1' : 'active2'"
          v-for="(item, index) in perList"
          :key="item.id"
        >
          <div class="infos">
            <div class="infos-R">
              <p class="name one-txt-cut">{{ item.name }}</p>
              <p class="work txt-cut">{{ item.work }}</p>
            </div>
            <div class="infos-L">
              <img
                class="img-icon"
                src="../assets/image/personnel/icon1.png"
                @click="editPro(item.id)"
              />

              <img
                class="img-icon"
                src="../assets/image/personnel/icon2.png"
                @click="disablePro(item.id, index)"
              />
            </div>
          </div>
          <div class="tags">
            <div class="tagItems one-txt-cut">
              {{ item.category === 1 ? "供应商" : "客户" }}
            </div>
          </div>
        </div>
      </div>
      <div class="lists" v-else>
        <MyNothing></MyNothing>
      </div>
      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        @current-change="handleCurrentChange"
        v-if="perList.length"
      >
      </el-pagination>
    </div>
    <MyNav></MyNav>
    <el-dialog
      class="big-pop"
      title=" "
      :visible.sync="addShow"
      width="753px"
      :before-close="setAddClose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @open="openDialog"
    >
      <div class="big-main">
        <div class="ware-title">
          <div class="left-line"></div>
          {{ PopTitle }}
        </div>
        <div class="add-form">
          <el-form
            ref="addForm"
            :rules="rules"
            :model="form"
            hide-required-asterisk
          >
            <el-form-item
              label="名称"
              :label-width="formLabelWidth"
              prop="name"
              key="name"
            >
              <el-input
                v-model.trim="form.name"
                autocomplete="off"
                placeholder="请输入名称"
                maxlength="20"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="电话"
              v-model="form.phone"
              :label-width="formLabelWidth"
              prop="phone"
              key="phone"
            >
              <el-input
                v-model.trim="form.phone"
                autocomplete="off"
                placeholder="请输入电话"
                maxlength="20"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="类型"
              :label-width="formLabelWidth"
              prop="category"
              key="category"
            >
              <el-radio-group v-model="form.category">
                <el-radio :label="1">供应商</el-radio>
                <el-radio :label="2">客户</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item
              label="主营内容"
              :label-width="formLabelWidth"
              prop="work"
              key="work"
            >
              <el-input
                type="textarea"
                v-model.trim="form.work"
                autocomplete="off"
                placeholder="请输入主营内容"
                maxlength="200"
                rows="5"
                resize="none"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="dialog-footer">
          <el-button class="ok-button" type="primary" @click="okAdd('addForm')"
            >确 定</el-button
          >
          <el-button class="no-button" @click="noAdd('addForm')"
            >取 消</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  showFullScreenLoading,
  tryHideFullScreenLoading,
} from "@/utils/screenLoading";
import {
  getLinkmanListApi,
  getLinkmanInfoApi,
  postAddLinkmanApi,
  postEditLinkmanApi,
  delLinkmanApi,
} from "@/request/api";
import { mapState } from "vuex";
export default {
  components: {
    MyNav: () => import("@/components/MyNav.vue"),
    MyNothing: () => import("@/components/MyNothing.vue"),
    PageTop: () => import("@/components/PageTop.vue"),
  },
  data() {
    return {
      total: 20,
      last_page: 1,
      per_page: 10,
      current_page: 1,
      perList: [],
      linkman_id: "",
      PopTitle: "",
      addShow: false,
      form: {
        name: "",
        phone: "",
        work: "",
        content: "",
        category: "",
      },
      title: undefined,
      mtype: undefined,
      rules: {
        name: [
          {
            required: true,
            whitespace: true,
            message: "请输入姓名",
            trigger: "blur",
          },
        ],
        phone: {
          required: true,
          pattern:
            /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
          message: "手机格式错误",
          trigger: "blur",
        },
        work: [
          {
            required: true,
            whitespace: true,
            message: "请输入主营内容",
            trigger: "blur",
          },
        ],

        category: [
          { required: true, message: "请选择类型", trigger: "change" },
        ],
      },
      formLabelWidth: "120px",
    };
  },
  created() {
    this.getMemberListApi();
  },
  computed: {
    ...mapState({
      farmID: (state) => state.user.farmID,
    }),
  },
  methods: {
    searchLinkman({ name, type }) {
      this.title = name ? name : undefined;
      this.mtype = +type ? +type : undefined;
      this.current_page = 1;
      this.getMemberListApi();
    },
    disablePro(id, index) {
      this.linkman_id = id;
      this.$confirm("此操作将删除该供应商, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.perList.splice(index, 1);
          if (!this.perList.length) {
            this.current_page = 1;
          }
          this.delMemberApi();
        })
        .catch(() => {
          this.$notify({
            title: "提示",
            message: "已取消操作",
          });
        });
    },
    noAdd(formName) {
      this.addShow = false;
      this.$refs[formName].resetFields();
      this.form = {
        name: "",
        work: "",
        content: "",
        phone: "",
        category: "",
      };
    },
    editPro(id) {
      this.linkman_id = id;
      this.getMemberInfoApi(id);
      this.PopTitle = "编辑供应商";
      this.addShow = true;
    },
    okAdd(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) return;
        if (this.PopTitle === "添加供应商") {
          this.postAddMemberApi();
        } else {
          this.postEditMemberInfoApi();
        }
      });
    },
    setAddClose() {
      this.addShow = false;
      this.form = {
        name: "",
        work: "",
        content: "",
        phone: "",

        category: "",
      };
    },
    openDialog() {
      this.$nextTick(() => {
        this.$refs.addForm.clearValidate();
      });
    },
    addLands() {
      this.PopTitle = "添加供应商";
      this.addShow = true;
    },
    async delMemberApi() {
      const { code } = await delLinkmanApi({
        linkman_id: this.linkman_id,
      });
      if (code === 0) {
        this.$notify({
          title: "成功",
          message: "已删除",
          type: "success",
        });
      }
    },
    async postEditMemberInfoApi() {
      const { code } = await postEditLinkmanApi({
        ...this.form,
        linkman_id: this.linkman_id,
      });
      if (code === 0) {
        this.getMemberListApi();
        this.$notify({
          title: "成功",
          message: "已修改",
          type: "success",
        });
        this.addShow = false;
        this.form = {
          name: "",
          work: "",
          content: "",
          category: "",
        };
      }
    },
    async postAddMemberApi() {
      const { code } = await postAddLinkmanApi({
        ...this.form,
        manor_id: this.farmID,
      });
      if (code === 0) {
        this.getMemberListApi();
        this.$notify({
          title: "成功",
          message: "已添加",
          type: "success",
        });
        this.addShow = false;
        this.imageUrl = "";
        this.form = {
          name: "",
          work: "",
          content: "",
          category: "",
        };
      }
    },
    async getMemberInfoApi(linkman_id) {
      const { code, results } = await getLinkmanInfoApi({
        linkman_id,
      });
      if (code === 0) {
        this.form = results;
      }
    },
    async getMemberListApi() {
      showFullScreenLoading();
      const { code, results } = await getLinkmanListApi({
        manor_id: this.farmID,
        pn: this.current_page,
        name: this.title,
        category: this.mtype,
      });
      if (code === 0) {
        this.total = results.count;
        this.per_page = results.ps;
        this.current_page = results.pn;
        this.perList = results.data;
      }
      tryHideFullScreenLoading();
    },
    handleCurrentChange(val) {
      this.current_page = val;
      this.getMemberListApi();
    },
  },
};
</script>

<style lang="less" scoped>
.linkman {
  width: 100%;
  min-height: 100vh;
  padding-top: 62px;
  background-color: #071a2c;
  padding: 62px 48px 100px;
  .mains {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 12px 0;
    background-color: #0e2138;
    padding: 22px 48px;

    .lists {
      width: 100%;
      display: flex;
      flex-wrap: wrap;

      .items {
        width: 326px;
        height: 149px;
        border-radius: 8px;
        margin: 0 20px 39px 0;
        display: flex;
        flex-direction: column;
        padding: 18px;

        &:hover .infos .infos-L {
          display: block;
        }

        &:nth-of-type(5n) {
          margin-right: 0;
        }

        &.active1 {
          background: url(../assets/image/personnel/bgc1.png) no-repeat 0 0;
          background-size: cover;
        }

        &.active2 {
          background: url(../assets/image/personnel/bgc2.png) no-repeat 0 0;
          background-size: cover;
        }

        .infos {
          width: 100%;
          margin-bottom: 17px;
          display: flex;
          justify-content: space-between;
          position: relative;
          .infos-R {
            width: 100%;
            color: #fff;
            .name {
              width: 100%;
              font-size: 18px;
              font-weight: bold;
              margin-bottom: 12px;
            }
            .work {
              width: 100%;
              font-size: 14px;
            }
          }

          .infos-L {
            display: none;
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            text-align: right;

            .img-icon {
              width: 30px;
              height: 30px;
              margin-right: 8px;
              display: inline-block;
              cursor: pointer;
              &:last-of-type {
                margin-right: 0;
              }
            }
          }
        }

        .tags {
          width: 100%;
          display: flex;
          align-items: center;

          .tagItems {
            background-color: rgba(0, 0, 0, 0.2);
            height: 25px;
            max-width: 116px;
            border-radius: 13px;
            line-height: 25px;
            text-align: center;
            color: #fff;
            font-size: 14px;
            margin-right: 10px;
            padding: 0 15px;

            &:last-of-type {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
  .big-pop {
    /deep/.el-textarea__inner {
      border-color: #385576;
      background-color: #081c30;
      color: #57708d;
    }
    /deep/.el-dialog {
      width: 753px !important;
      background-color: inherit;
    }

    /deep/.el-dialog__body {
      padding: 0;
    }

    /deep/.el-dialog__headerbtn {
      font-size: 26px;
      top: 64px;
      right: 50px;
      font-weight: bold;
    }

    /deep/.el-button span {
      color: #fff;
    }

    /deep/.el-dialog__headerbtn {
      top: 71px;
      right: 44px;
    }

    /deep/.dialog-footer {
      padding: 0 0 0 119px;

      .el-form-item__content {
        width: 353px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    .ok-button {
      width: 150px;
      height: 40px;
      margin-right: 54px;
    }

    .no-button {
      width: 150px;
      height: 40px;
      border: 1px solid #385576;
      background-color: #0f243d;
    }

    .big-main {
      width: 753px;
      height: 521px;
      background: url(../assets/image/personnel/popBgB.png) no-repeat center
        center;
      background-size: cover;
      padding: 45px 55px 0 55px;
      .ware-title {
        font-size: 22px;
        color: #fff;
        font-weight: bold;
        display: flex;
        margin-bottom: 40px;
        align-items: center;

        .left-line {
          background-color: #fff;
          width: 4px;
          height: 21px;
          border-radius: 3px;
          margin-right: 10px;
        }
      }

      .add-form {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .el-form {
          width: 100%;
        }
      }

      /deep/.el-input__inner {
        background-color: #081c30;
        border: 1px solid #385576;
        color: #57708d;
        width: 282px;
      }

      /deep/.el-form-item__label {
        font-size: 14px;
        color: #fff;
        padding: 0 24px 0 0;
      }

      /deep/.el-radio__input.is-checked + .el-radio__label {
        color: #fff;
      }

      /deep/.el-radio {
        color: #fff;
      }
    }
  }
}
</style>
